import axios from "axios";
import urls from "../../data/urls";

//https://dev.to/xjamundx/diy-pre-loading-for-faster-data-fetching-24ka
const loadProjects = () => axios.get(urls.flask.projects,).catch(function (error) {});
const loadCurrentUser = () => axios.get(urls.flask.current_user,).catch(function (error) {});
// console.log("preload");
window.__DATA_LOADER_CACHE__ = {
    projects: loadProjects(),
    currentUser: loadCurrentUser()
}